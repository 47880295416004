import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import Homepage from './components/HomePage';
import LoginPage from './components/authPage/LoginModal';
import Dashboard from './components/functionPages/Consumer/Dashboard';
import Complaints from './components/functionPages/Consumer/complaints/Complaints';
import SubscriptionPage from './components/functionPages/Consumer/SubscriptionPage';
import CommunityPage from './components/functionPages/Consumer/communityPage';
import Chat from './components/functionPages/Consumer/chat';
import ReportUrl from './components/functionPages/Consumer/reportUrl';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReturnRefundPolicy from './components/ReturnRefundPolicy';
import TermsOfUse from './components/TermsOfUse';
import ShippingAndDelivery from './components/ShippingAndDelivery';
import ChatDummy from './components/functionPages/Consumer/chatDummy';

import B_Dashboard from './components/functionPages/Business/B_Dashboard';
import Overview from './components/functionPages/Business/Overview';
import Tickets from './components/functionPages/Business/Tickets';
import Page3 from './components/functionPages/Business/Page3';
import B_Admin from './components/functionPages/Business/B_Admin';
import B_Report from './components/functionPages/Business/B_Report';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userData = localStorage.getItem('userData');
        const currentPath = location.pathname;

        // Exclude paths from redirection
        const excludedPaths = ['/privacypolicy', '/return-refund-policy', '/terms-of-use', '/ShippingAndDelivery'];

        if (token && userData) {
            // Redirect if user is on the homepage or login page
            if (currentPath === '/' || currentPath === '/login') {
                navigate('/dashboard/complaints');
            }
        } else {
            // Redirect to homepage if not logged in, unless on an excluded path
            if (!excludedPaths.includes(currentPath)) {
                navigate('/');
            }
        }
    }, [navigate, location.pathname]);

    return (
        <>
            <Navigation />
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Homepage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/terms-of-use' element={<TermsOfUse />} />
                <Route path='/return-refund-policy' element={<ReturnRefundPolicy />} />
                <Route path='/ShippingAndDelivery' element={<ShippingAndDelivery />} />

                {/* Consumer Dashboard */}
                <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<Navigate to="complaints" />} /> {/* Default redirection */}
                    <Route path="complaints" element={<Complaints />} />
                    <Route path="subscription" element={<SubscriptionPage />} />
                    <Route path="community" element={<CommunityPage />} />
                    <Route path="chat" element={<ChatDummy />} />
                    <Route path="reportUrl" element={<ReportUrl />} />
                </Route>

                {/* Business Dashboard */}
                <Route path="/Businessdashboard" element={<B_Dashboard />}>
                    <Route index element={<Navigate to="Overview" />} /> {/* Default redirection */}
                    <Route path="Overview" element={<Overview />} />
                    <Route path="Tickets" element={<Tickets />} />
                    <Route path="page3" element={<Page3 />} />
                    <Route path="admin" element={<B_Admin />} />
                    <Route path="reports" element={<B_Report />} />
                </Route>

                {/* Add other routes here */}
            </Routes>
        </>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
