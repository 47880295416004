import React, { useEffect, useState } from "react";
import dummyData from "./dummy.json"; // Import the dummy data

const Overview = () => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch userType and email from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userType = userData?.userType;
    const userEmail = userData?.email;

    if (userType === 4) {
      // If userType is 4, show all tickets
      setTickets(dummyData);
    } else if (userType === 5 && userEmail) {
      // Filter tickets based on user email for userType 5
      const filteredTickets = dummyData.filter(
        (ticket) => ticket.email === userEmail
      );

      if (filteredTickets.length > 0) {
        setTickets(filteredTickets);
      } else {
        setError("No tickets found for this user.");
      }
    } else {
      setError("User type or email not found.");
    }
  }, []);

  return (
    <div>
      <h2>Ticket Overview</h2>

      {/* Show error if there's an issue */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Conditionally render the table */}
      {tickets.length > 0 ? (
        <table border="1" cellPadding="10" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Email</th>
              <th>Tickets Generated</th>
              <th>Tickets Open</th>
              <th>Tickets Closed</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket, index) => (
              <tr key={index}>
                <td>{ticket.email}</td>
                <td>{ticket.ticketsGenerated}</td>
                <td>{ticket.ticketsOpen}</td>
                <td>{ticket.ticketsClosed}</td>
                <td>
                  {ticket.status === "active" ? (
                    <span style={{ color: "green" }}>🟢 Active</span>
                  ) : (
                    <span style={{ color: "red" }}>🔴 Inactive</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !error && <p>No tickets available.</p>
      )}
    </div>
  );
};

export default Overview;
