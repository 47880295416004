// src/components/B_Dashboard.js
import React from 'react';
import B_Sidebar from './B_Sidebar';
import { Outlet } from 'react-router-dom';

const B_Dashboard = () => {
  return (
    <div className="d-flex">
      {/* Sidebar */}
      <B_Sidebar />

      {/* Content Area */}
      <div
        className="p-4"
        style={{
          marginLeft: '250px', // The same width as the sidebar to avoid overlap
          width: 'calc(100% - 250px)', // Adjust the content width
          marginTop: '60px', // Add top margin for consistency
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default B_Dashboard;
