import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

const B_Sidebar = () => {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    // Fetch userData from localStorage and extract userType
    const userData = localStorage.getItem('userData');
    
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      setUserType(parsedUserData.userType); // Access userType from userData
    }
  }, []);

  return (
    <div
      className="bg-light vh-100"
      style={{
        width: '250px',
        position: 'fixed',
        top: '60px',
        left: 0,
        padding: '20px',
      }}
    >
      <h4>Business Dashboard</h4>
      <ListGroup>
        {/* Conditionally show sidebar options based on userType */}
        {userType === 4 && (
          <>
            <ListGroup.Item action as={Link} to="/Businessdashboard/admin">
              Admin
            </ListGroup.Item>
            <ListGroup.Item action as={Link} to="/Businessdashboard/Overview">
              Overview
            </ListGroup.Item>
            <ListGroup.Item action as={Link} to="/Businessdashboard/Tickets">
              Tickets
            </ListGroup.Item>
            <ListGroup.Item action as={Link} to="/Businessdashboard/reports">
              Reports
            </ListGroup.Item>
          </>
        )}

        {userType === 5 && (
          <>
            <ListGroup.Item action as={Link} to="/Businessdashboard/Overview">
              Overview
            </ListGroup.Item>
            <ListGroup.Item action as={Link} to="/Businessdashboard/Tickets">
              Tickets
            </ListGroup.Item>
            <ListGroup.Item action as={Link} to="/Businessdashboard/page3">
              Page 3
            </ListGroup.Item>
          </>
        )}

        {/* Common links can be added here for all user types */}
      </ListGroup>
    </div>
  );
};

export default B_Sidebar;
