import React, { useEffect, useState } from 'react';
import { Table, Container, InputGroup, FormControl, Button, Modal, Spinner } from 'react-bootstrap';
import backgroundImage from '../../../assets/images/subtle-prism1.png';
import { FaArrowUp } from 'react-icons/fa'; 
import { PiTrendUp } from "react-icons/pi";
const ReportUrl = () => {
  const [links, setLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [newLink, setNewLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState('');

  useEffect(() => {
    // Fetch initial data from API
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Links/get-links`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setLinks(data.filter(link => link.state !== 0 && link.state !== 1 )); // Filter out state 0 links
    };

    fetchData();
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Links/search?link=${encodeURIComponent(searchQuery)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
        setShowModal(true);
      } else {
        console.error('Search failed');
      }
    } catch (error) {
      console.error('Network error:', error);
    } finally {
      setIsLoading(false);
      setSearchQuery(''); // Clear the search bar
    }
  };
  

  const handleReport = async () => {
    setShowReportModal(true);
  };

  const handleReportSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Links/create-link`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ link: newLink, state: 0 })
      });

      if (response.ok) {
        setReportStatus('Thank you for helping us prevent phishing!');
        setNewLink('');
      } else {
        setReportStatus('Error reporting the URL. Please try again.');
      }
    } catch (error) {
      setReportStatus('Network error. Please try again.');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setShowReportModal(false);
        setReportStatus('');
      }, 2000);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: '1rem 0',
        color: '#fff',
        top: '60px',
        position: 'fixed', // Fixes the entire page
        width: '80%',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
    <Container style={{ marginTop: '60px', position: 'relative' }}>
      {/* Fixed Search Bar Container */}
      <div style={{ 
  position: 'fixed', 
  top: '60px', 
  left: '20%', 
  width: '80%', 
  zIndex: '1000', 
  backgroundColor: '#fff', 
  padding: '10px 0', 
  boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
  borderBottom: '1px solid #ddd',
  display: 'flex',
  justifyContent: 'center', // Center content within the container
  alignItems: 'center', // Vertically center the content
  // position: 'relative' // Allow absolute positioning of the button
}}>
  {/* Report URL button */}
  <Button 
    variant="danger" 
    onClick={handleReport} 
    style={{ 
      position: 'absolute', 
      left: '10px', 
      borderRadius: '0', 
      height: '38px', // Ensure height matches the search bar
      padding: '0 15px' // Adjust padding to match height
    }} 
  >
    Report URL
  </Button>
  
  {/* Centered search bar */}
  <InputGroup className="mb-3" style={{ 
      maxWidth: '600px', 
      width: '100%', 
      margin: '0 auto', 
      height: '38px', // Ensure the height matches the button
      paddingTop:'10px',
    }}>
    <FormControl
      placeholder="Search"
      aria-label="Search"
      aria-describedby="search-button"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      style={{ 
        borderRadius: '0', 
        height: '38px' // Ensure the height matches the button
      }}
    />
    
    <Button 
      variant="primary" 
      id="search-button" 
      onClick={handleSearch}
      style={{ 
        borderRadius: '0', 
        height: '38px' // Ensure the height matches the button
      }}
    >
      {isLoading ? <Spinner animation="border" size="sm" /> : 'Search'}
    </Button>
  </InputGroup>
</div>

      {/* Main Content Container */}
      <Container style={{ paddingTop: '10px' , height:'100vh',  marginLeft: '0px'}}>
        <div style={{ marginBottom: '10px' }}>
          <h4 style={{ marginBottom: '12px', color:'black' }}>Trending Suspicious URLs</h4>
          <div style={{ overflowX: 'auto' }}>
          <Table 
  striped 
  bordered 
  hover 
  style={{ 
    minWidth: '100%', 
    backgroundColor: 'transparent',
    userSelect: 'none', // Disable text selection
    pointerEvents: 'none', // Disable pointer events for interaction (like copying)
  }}>
  <thead style={{ 
    position: 'sticky', 
    top: '0', 
    backgroundColor: 'transparent', 
    zIndex: '1',
    pointerEvents: 'auto', // Re-enable pointer events for header interactions (if needed)
  }}>
    <tr>
      <th>#</th>
      <th>URL</th>
      <th>State</th>
    </tr>
  </thead>
  <tbody style={{ 
    backgroundColor: 'transparent',
    userSelect: 'none', // Disable text selection in the body
  }}>
    {links.map((link, index) => (
      <tr key={link.id}>
        <td>{index + 1}</td>
        <td>
          {link.url} 
          {/* Conditionally render the trending icon after the first five items */}
          {index < 5 && <PiTrendUp style={{ color: 'black', marginLeft: '5px' }} />}
        </td>
        <td style={{ color: UrlState[link.state].color, fontSize: UrlState[link.state].fontSize, fontWeight: UrlState[link.state].fontWeight }}>
          {UrlState[link.state].text}
        </td>
      </tr>
    ))}
  </tbody>
</Table>


          </div>
        </div>
      </Container>

      {/* Modal for Search Results */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>URL</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((result, index) => (
                result.state !== 0 ? (
                  <tr key={result.id}>
                    <td>{index + 1}</td>
                    <td>{result.url}</td>
                    <td style={{ color: UrlState[result.state].color, fontSize: UrlState[result.state].fontSize, fontWeight: UrlState[result.state].fontWeight }}>
                      {UrlState[result.state].text}
                    </td>
                  </tr>
                ) : null
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Modal for Reporting URL */}
      <Modal show={showReportModal} onHide={() => setShowReportModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Report a Phishing URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <Container>
              <p>Help us prevent phishing by reporting suspicious URLs.</p>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Insert phishing URL"
                  aria-label="Insert phishing URL"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                />
              </InputGroup>
              <Button variant="primary" onClick={handleReportSubmit}>Submit</Button>
              {reportStatus && <p>{reportStatus}</p>}
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </Container>
    </div>
  );
};

const UrlState = {
  0: { text: 'Unverified', color: 'gray', fontSize: '18px', fontWeight: 'normal' },
  1: { text: 'Safe', color: 'green', fontSize: '17px', fontWeight: 'bold' },
  2: { text: 'Unsafe', color: 'red', fontSize: '17px', fontWeight: 'bold' },
  3: { text: 'Suspicious', color: 'orange', fontSize: '17px', fontWeight: 'bold' },
};

export default ReportUrl;
