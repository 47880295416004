import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import ticketsData from './tickets.json'; // Adjust the path to your JSON file

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [userType, setUserType] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Fetch userType and userId from localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData) {
      setUserType(userData.userType);
      setUserId(userData.userId);
    }

    // Filter tickets based on userType
    if (userData.userType === 4) {
      // Show all tickets for userType 4
      setTickets(ticketsData);
    } else if (userData.userType === 5) {
      // Show only tickets that match the user's ID for userType 5
      const filteredTickets = ticketsData.filter(ticket => ticket.userId === userData.userId);
      setTickets(filteredTickets);
    }
  }, []);

  return (
    <div>
      <h2>Tickets Overview</h2>

      {/* Conditionally render the tickets */}
      {tickets.length > 0 ? (
        <Row>
          {tickets.map((ticket, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card style={{ height: '100%' }}>
                <Card.Body>
                  <Card.Title>Ticket ID: {ticket.ticketID}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {ticket.ticketType === "Auto-Generated" ? ticket.emailSubject : ticket.title}
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>User ID:</strong> {ticket.userId} <br />
                    <strong>Priority:</strong> {ticket.priority} <br />
                    <strong>Status:</strong> {ticket.ticketStatus} <br />
                    <strong>Assigned Agent:</strong> {ticket.assignedAgent} <br />
                    <strong>Date Created:</strong> {new Date(ticket.dateCreated).toLocaleString()} <br />
                    <strong>Source:</strong> {ticket.ticketSource}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p>No tickets available.</p>
      )}
    </div>
  );
};

export default Tickets;
