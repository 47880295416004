import React, { useState } from 'react';
import { Card, Row, Col, Table, Button, Modal, Form } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import emailData from './dummy.json'; // Import the JSON data

const B_Admin = () => {
  // Example data; replace this with real data from an API or backend
  const totalTickets = 120;
  const ticketsCreated = 30;
  const ticketsSolved = 85;
  const ticketsRemaining = totalTickets - ticketsSolved;

  // Email-related calculations
  const totalEmails = emailData.length;
  const activeEmails = emailData.filter((item) => item.status === 'active').length;

  // Modal state for adding new email
  const [show, setShow] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newStatus, setNewStatus] = useState('active');

  // Modal state for editing email
  const [editShow, setEditShow] = useState(false);
  const [editEmail, setEditEmail] = useState('');
  const [editStatus, setEditStatus] = useState('');

  const handleClose = () => setShow(false);
  const handleEditClose = () => setEditShow(false);

  const handleShow = () => setShow(true);

  const handleSave = () => {
    console.log('New Email:', newEmail, 'Status:', newStatus);
    setNewEmail('');
    setNewStatus('active');
    handleClose();
  };

  const handleEditSave = () => {
    console.log('Edited Email:', editEmail, 'New Status:', editStatus);
    setEditShow(false);
  };

  const handleEditClick = (email, status) => {
    setEditEmail(email);
    setEditStatus(status);
    setEditShow(true);
  };

  // Inline styles for cards
  const cardStyle = (bgColor) => ({
    backgroundColor: bgColor,
    color: '#fff',
    cursor: 'pointer',
    transition: 'transform 0.3s, box-shadow 0.3s',
    transform: 'scale(1)', // Default scale
  });

  const cardHoverStyle = {
    transform: 'scale(1.1)', // Scale the card on hover
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow on hover
  };

  // Export table data to PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Email and Ticket Data', 20, 10);

    // AutoTable will take in columns and rows
    doc.autoTable({
      head: [['Email', 'Tickets Generated', 'Tickets Open', 'Tickets Closed', 'Status']],
      body: emailData.map((item) => [
        item.email,
        item.ticketsGenerated,
        item.ticketsOpen,
        item.ticketsClosed,
        item.status === 'active' ? 'Active' : 'Inactive',
      ]),
    });

    doc.save('email_ticket_data.pdf');
  };

  return (
    <div className="p-4">
      {/* Top right company name */}
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        <h3>Dummy Company Name</h3>
      </div>

      {/* Row for the cards */}
      <Row className="mb-4">
        <Col md={3}>
          <Card
            className="text-center"
            style={cardStyle('#007bff')} // bg-primary
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#007bff'));
            }}
          >
            <Card.Body>
              <Card.Title>Total Tickets</Card.Title>
              <Card.Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {totalTickets}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card
            className="text-center"
            style={cardStyle('#28a745')} // bg-success
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#28a745'));
            }}
          >
            <Card.Body>
              <Card.Title>Tickets Created</Card.Title>
              <Card.Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {ticketsCreated}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card
            className="text-center"
            style={cardStyle('#ffc107')} // bg-warning
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#ffc107'));
            }}
          >
            <Card.Body>
              <Card.Title>Tickets Solved</Card.Title>
              <Card.Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {ticketsSolved}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card
            className="text-center"
            style={cardStyle('#dc3545')} // bg-danger
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#dc3545'));
            }}
          >
            <Card.Body>
              <Card.Title>Tickets Remaining</Card.Title>
              <Card.Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {ticketsRemaining}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Row for additional cards below */}
      <Row className="mb-4">
        <Col md={4}>
          <Card
            className="text-center"
            style={cardStyle('#17a2b8')} // bg-info
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#17a2b8'));
            }}
          >
            <Card.Body>
              <Card.Title>Total Emails</Card.Title>
              <Card.Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {totalEmails}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card
            className="text-center"
            style={cardStyle('#28a745')} // bg-success
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#28a745'));
            }}
          >
            <Card.Body>
              <Card.Title>Active Emails</Card.Title>
              <Card.Text style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {activeEmails}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card
            className="text-center"
            style={cardStyle('#007bff')} // bg-primary
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, cardHoverStyle);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, cardStyle('#007bff'));
            }}
          >
            <Card.Body>
              <Card.Title>Add Email</Card.Title>
              <Button variant="light" style={{ fontSize: '16px' }} onClick={handleShow}>
                Add New Email
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Adding Email */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Editing Email */}
      <Modal show={editShow} onHide={handleEditClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEditEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={editEmail}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formEditStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={editStatus}
                onChange={(e) => setEditStatus(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Export and Table */}
      <h3>Email and Ticket Data</h3>
      <div style={{ marginBottom: '10px' }}>
        <Button variant="primary" onClick={exportPDF}>
          Export to PDF
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Tickets Generated</th>
            <th>Tickets Open</th>
            <th>Tickets Closed</th>
            <th>Status</th>
            <th>Action</th> {/* New Action column */}
          </tr>
        </thead>
        <tbody>
          {emailData.map((item, index) => (
            <tr key={index}>
              <td>{item.email}</td>
              <td>{item.ticketsGenerated}</td>
              <td>{item.ticketsOpen}</td>
              <td>{item.ticketsClosed}</td>
              <td>{item.status === 'active' ? '🟢 Active' : '🔴 Inactive'}</td>
              <td>
                <Button
                  variant="info"
                  onClick={() => handleEditClick(item.email, item.status)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default B_Admin;
