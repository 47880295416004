// src/components/pages/Page3.js
import React from 'react';

const Page3 = () => {
  return (
    <div>
      <h2>Page 3 Content</h2>
      <p>This is the content for page 3.</p>
    </div>
  );
};

export default Page3;
