import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import backgroundImage from "../assets/images/subtle-prism1.png";
import playStoreImg from "../assets/images/GetItOnGooglePlay_Badge_Web_color_English.png";
import appStoreImg from "../assets/images/apple12.png";
import { useEffect } from "react";
import blob_pattern from "../assets/images/feature/magicpattern-blob-1724557518369.png";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useSpring, animated } from "react-spring";
import fraud from "../assets/images/feature/error_11869178.png";
import support from "../assets/images/feature/24-7_2011994.png";
import chat from "../assets/images/feature/communication_11681474.png";
import call from "../assets/images/feature/headset_4890198.png";
import platform_phone from "../assets/images/platform/apple_mockup-left.png";
import platform_pc from "../assets/images/platform/mac-front.png";
import platform_tab from "../assets/images/platform/tab-portrait.png";
import LoginSignupModal from "./LoginSignupModal";
import LoginModal from "./authPage/LoginModal";
// import LoginModal from './LoginModal'; // Import your LoginModal component
import SignupModal from "./authPage/SignupModal"; // Import your SignupModal component
import USAFlag from "../assets/flags/usa.png"; // Import USA flag
import IndiaFlag from "../assets/flags/india.png"; // Import India flag
import banner_mockup from "../assets/images/Mockup.png";
import { Dropdown, Button } from "react-bootstrap";
const testimonials = [
  {
    text: "Thanks to this service, I was able to avoid a phishing scam that could have cost me thousands. They were available and supportive whenever I needed help.",
    name: "Alice Johnson",
  },
  {
    text: "Exceptional support during a critical phishing attack. Their timely advice saved me from losing my personal information.",
    name: "Michael Smith",
  },
  {
    text: "I can't thank this team enough for their assistance during a phishing scam. They were always available to help and provided excellent guidance.",
    name: "Emily Davis",
  },
  {
    text: "Their expertise in handling phishing scams was impressive. I felt secure knowing they were there to support me through the entire process.",
    name: "David Brown",
  },
  {
    text: "An invaluable service that acted swiftly when I was targeted by scammers. Their advice was crucial in protecting my finances.",
    name: "Sophia Williams",
  },
  {
    text: "Their proactive approach and constant availability made a significant difference when dealing with a phishing scam. Highly recommend their services.",
    name: "James Wilson",
  },
  {
    text: "I received immediate assistance when scammers tried to deceive me. Their support was crucial in ensuring my personal data remained safe.",
    name: "Olivia Taylor",
  },
  {
    text: "A reliable partner in times of online threats. Their prompt action and expert advice prevented a potential disaster during a phishing attack.",
    name: "William Anderson",
  },
  {
    text: "Outstanding service that provided critical support when scammers tried to exploit me. Their professionalism and availability were top-notch.",
    name: "Isabella Martinez",
  },
];

const Homepage = () => {
  //Start of platform section
  const [selectedPlatform, setSelectedPlatform] = useState("Phones");
  const [currency, setCurrency] = useState("USD");
  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
  };

  const renderImage = () => {
    const imageStyle = { width: "100%", height: "550px", maxWidth: "400px" }; // Adjust the size as needed
    const imageStyleMac = { width: "100%", height: "550px", maxWidth: "600px" }; // Adjust the size as needed
    switch (selectedPlatform) {
      case "Phones":
        return (
          <img
            src={platform_phone}
            alt="Phones"
            className="img-fluid"
            style={imageStyle}
          />
        );
      case "PC or Mac":
        return (
          <img
            src={platform_pc}
            alt="PC or Mac"
            className="img-fluid"
            style={imageStyleMac}
          />
        );
      case "Web":
        return (
          <img
            src={platform_tab}
            alt="Web"
            className="img-fluid"
            style={imageStyle}
          />
        );
      default:
        return (
          <img
            src={platform_phone}
            alt="Default"
            className="img-fluid"
            style={imageStyle}
          />
        );
    }
  };

  //Start of Mobile mockeup setion
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // Add event listener to detect window resizing and adjust the layout accordingly
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bannerStyle = {
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0)",
    height: "auto",
    width: "100%",
    maxWidth: isMobile ? "450px" : "600px", // Mobile: 250px, Desktop: 400px
    objectFit: "contain",
    transform: "rotate(0deg)",
  };

  //End of Mobile mockeup setion

  // Start of testimonials
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsPerPage = window.innerWidth <= 768 ? 1 : 3; // Show 1 on mobile, 3 on larger screens
  const totalPages = Math.ceil(testimonials.length / testimonialsPerPage);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // Slide every 3 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalPages - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === totalPages - 1 ? 0 : prevIndex + 1
    );
  };

  const slideProps = useSpring({
    transform: `translateX(-${currentIndex * (100 / totalPages)}%)`,
    config: { tension: 220, friction: 30 },
  });
  // End of testimonials

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollTop = window.pageYOffset;
      const parallaxElement = document.getElementById("home");
      parallaxElement.style.backgroundPositionY = `${scrollTop * 0.5}px`;
    };

    window.addEventListener("scroll", parallaxEffect);

    return () => {
      window.removeEventListener("scroll", parallaxEffect);
    };
  }, []);
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleMouseEnter = (cardId) => {
    setHoveredCard(cardId);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const commonListItemStyle = {
    position: "relative",
    paddingLeft: "30px", // Space for the star
    marginBottom: "10px",
    textAlign: "left",
  };

  const starIconStyle = {
    position: "absolute",
    left: "0",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "18px",
    color: "#ffcc00", // Adjust color to match your design
  };

  //Contact section
  // Inline styles for the section
  const sectionStyle = {
    position: "relative",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "5rem 0",
    textAlign: "center",
    color: "#fff",
  };

  // Inline styles for form inputs and button
  const inputStyle = {
    borderRadius: "5px",
    marginBottom: "1rem",
    padding: "10px",
    border: "1px solid #ccc",
  };

  const buttonStyle = {
    width: "100%",
    borderRadius: "5px",
    padding: "10px",
    backgroundColor: "#007bff", // Primary button color
    color: "#fff",
    border: "none",
    cursor: "pointer",
  };
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleLogin = () => setShowLoginModal(true);
  const handleSignup = () => setShowSignupModal(true);

  const plans = [
    {
      title: "Basic Plan",
      priceINR: 14999,
      priceUSD: 119,
      perMonthINR: "₹83.33",
      perMonthUSD: "$36.66",
    },
    {
      title: "Standard Plan",
      priceINR: 24999,
      priceUSD: 199,
      perMonthINR: "₹75",
      perMonthUSD: "$33.16",
    },
    {
      title: "Premium Plan",
      priceINR: 34999,
      priceUSD: 389,
      perMonthINR: "₹31.25",
      perMonthUSD: "$16.20",
    },
    // {
    //   title: "1 Year Golden Plan",
    //   priceINR: 1250,
    //   priceUSD: 489,
    //   perMonthINR: "₹26",
    //   perMonthUSD: "$10.84",
    // },
  ];

  return (
    <>
      <style>
        {`
          .card-hover {
            transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
          }

          .card-hover:hover {
            transform: scale(1.1); /* Scale the card slightly on hover */
          }

          .card-default {
            transform: ${
              hoveredCard === null ? "scale(1.1)" : "scale(1)"
            }; /* Default scale and normal scale when any card is hovered */
            transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
          }

          .card-default:hover {
            transform: scale(1.1); /* Ensure the default card scales up on hover */
          }

          /* Common style for the fading line */
          .fading-line {
            display: block;
            position: absolute;
            bottom: -10px; /* Position the line below the text */
            left: 50%;
            width: 100%;
            height: .8px; /* Thickness of the line */
            background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%); /* Gradient effect */
            transform: translateX(-50%); /* Center the line horizontally */
            content: "";
          }

          /* Common styles for gradient lines */
          .gradient-line {
            position: absolute;
            left: 0;
            right: 0;
            height: 5px;
            background: linear-gradient(to right, #ff7e5f, #feb47b);
            border-radius: 18px; /* Applies border radius to all corners */
          }

          /* Specific top gradient line style */
          .gradient-line-top {
            top: 0;
            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
          }

          /* Specific bottom gradient line style */
          .gradient-line-bottom {
            bottom: 0;
            border-bottom-left-radius: 18px;
            border-bottom-right-radius: 18px;
          }

          /* Base button style */
          .btn-primary {
            display: inline-block;
            padding: 12px 24px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            background-color: #007bff;
            border: none;
            border-radius: 8px;
            text-align: center;
            text-decoration: none;
            transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;
            border-radius: 25px;
          }

          /* Hover effect */
          .btn-primary:hover {
            background-color: #0056b3;
            transform: scale(1.05);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }

          /* Focus effect (optional, for accessibility) */
          .btn-primary:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
          }
            @media (max-width: 768px) {
  .store-buttons {
    paddingBottom: '80px';  /* Increased padding for mobile devices */
  }
        `}
      </style>
      <div>
        <main>
          {/* Home Section */}
          <section
            id="home"
            className="py-5 bg-light text-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 d-flex flex-column justify-content-center">
                  <h1
                    className="display-4 font-weight-bolder text-uppercase"
                    style={{
                      color: "#ff5722",
                      fontWeight: "normal",
                      marginTop: window.innerWidth < 768 ? "30px" : "0",
                    }}
                  >
                    Welcome to PhishRoko™
                  </h1>
                  <p
                    className="lead mb-4 font-weight-bold"
                    style={{ fontSize: "30px", lineHeight: "1.5" }}
                  >
                    Now cybersecurity and affordability go hand in hand.
                  </p>
                  <p
                    className="lead mb-4 font-weight-bold"
                    style={{ fontSize: "30px", lineHeight: "1.5" }}
                  >
                    Whether you are a startup or an emerging business, you can
                    stay safe & secure from cyber crime.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/*Features Section*/}
          <section
            id="features"
            style={{ padding: "3rem 0", textAlign: "center" }}
          >
            <div className="container">
              <h2
                style={{
                  marginBottom: "3rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#333",
                }}
              >
                Features
              </h2>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      padding: "1.5rem",
                      border: "none",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 31px -9px rgba(0,0,0,0.75)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 50px rgba(0,0,0,0.75)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 31px -9px rgba(0,0,0,0.75)";
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100px",
                          height: "100px",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          src={blob_pattern}
                          alt="Safe and Secure"
                          style={{
                            width: "100px",
                            height: "100px",
                            transition: "transform 0.3s ease",
                          }}
                        />
                        <img
                          src={fraud}
                          alt="Lock"
                          style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                      <h5
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#333",
                        }}
                      >
                        Fraud Detection
                      </h5>
                      <p style={{ fontSize: "14px", color: "#666" }}>
                        Detection of social engineering attacks like phishing,
                        impersonation, theft, & various cyver frauds.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      padding: "1.5rem",
                      border: "none",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 31px -9px rgba(0,0,0,0.75)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 50px rgba(0,0,0,0.75)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 31px -9px rgba(0,0,0,0.75)";
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100px",
                          height: "100px",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          src={blob_pattern}
                          alt="24x7 Support"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <img
                          src={support}
                          alt="Lock"
                          style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                      <h5
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#333",
                        }}
                      >
                        24x7 Support
                      </h5>
                      <p style={{ fontSize: "14px", color: "#666" }}>
                        Get 24X7 support with our commited team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      padding: "1.5rem",
                      border: "none",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 31px -9px rgba(0,0,0,0.75)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 50px rgba(0,0,0,0.75)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 31px -9px rgba(0,0,0,0.75)";
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100px",
                          height: "100px",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          src={blob_pattern}
                          alt="Safe and Secure"
                          style={{
                            width: "100px",
                            height: "100px",
                            transition: "transform 0.3s ease",
                          }}
                        />
                        <img
                          src={chat}
                          alt="Lock"
                          style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                      <h5
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#333",
                        }}
                      >
                        Dedicated SOC
                      </h5>
                      <p style={{ fontSize: "14px", color: "#666" }}>
                        Dedicated Security Operations Center (SOC) Team.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div
                    className="card"
                    style={{
                      padding: "1.5rem",
                      border: "none",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 31px -9px rgba(0,0,0,0.75)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 50px rgba(0,0,0,0.75)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 0px 31px -9px rgba(0,0,0,0.75)";
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100px",
                          height: "100px",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          src={blob_pattern}
                          alt="24x7 Support"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <img
                          src={call}
                          alt="Lock"
                          style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                      <h5
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#333",
                        }}
                      >
                        Call Support
                      </h5>
                      <p style={{ fontSize: "14px", color: "#666" }}>
                        Get on-call support with our dedicated team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Platform Section*/}
          <section
            id="platform"
            className="py-5 text-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover", // Ensure the image covers the section
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat", // Prevent repetition
            }}
          >
            <div className="container">
              <h2
                style={{
                  marginBottom: "3rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#333",
                }}
              >
                MULTIPLATFORM SERVICE
              </h2>
              <div className="row">
                <div className="col-md-6">{renderImage()}</div>
                <div className="col-md-6">
                  <h2 style={{ fontWeight: "normal", color: "#333" }}>
                    Works on Several Platforms
                  </h2>
                  <div className="d-flex justify-content-center mb-3">
                    {["Phones", "PC or Mac", "Web"].map((platform) => (
                      <button
                        key={platform}
                        className={`btn ${
                          selectedPlatform === platform
                            ? "btn-warning"
                            : "btn-link"
                        }`}
                        onClick={() => handlePlatformChange(platform)}
                        style={{
                          padding: "1.5rem",
                          border: "none",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 31px -9px rgba(0,0,0,0.75)",
                          transition:
                            "transform 0.3s ease, box-shadow 0.3s ease",
                          marginRight: "1rem",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow =
                            "0px 0px 50px rgba(0,0,0,0.75)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow =
                            "0px 0px 31px -9px rgba(0,0,0,0.75)";
                        }}
                      >
                        {platform === "Phones"
                          ? "Smartphone"
                          : platform === "PC or Mac"
                          ? "Windows or Mac"
                          : "Email"}
                      </button>
                    ))}
                  </div>
                  <p style={{ fontSize: "25px" }}>
                    Our solution can be used across all platforms providing
                    seamless experience without any limitation.
                  </p>
                  <ul
                    style={{
                      textAlign: "left",
                      paddingLeft: "1.5rem",
                      fontSize: "20px",
                      marginTop: "50px",
                    }}
                  >
                    <li style={{ marginBottom: "15px" }}>
                      Get notification across all devices
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      Login to multiple devices from anywhere
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      Report threat from any device
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      Report threat via email
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      Multi Device Access
                    </li>
                    <li style={{ marginBottom: "15px" }}>
                      Receive alerts accross all devices
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Pricing Section */}
          <section
            id="pricing"
            className="py-5 text-center"
            style={{ position: "relative" }}
          >
            <div className="container">
              <h2
                className="mb-5"
                style={{ position: "relative", zIndex: "2" }}
              >
                Choose Your Plan
              </h2>
              <p style={{ position: "relative", zIndex: "2" }}>
                We offer plans to help you save from the scams that are coming
                your way and take all your savings.
              </p>
              <p style={{ position: "relative", zIndex: "2" }}>
                Plans are of different durations, choose one to stay safe.
              </p>

              {/* Row to contain the cards */}
              <div
                className="row d-flex justify-content-center"
                style={{ marginTop: "40px" }}
              >
                {plans.map((plan, index) => (
                  <div
                    className="col-md-4 d-flex justify-content-center mb-4"
                    key={index}
                  >
                    <div
                      className="card p-3 border-0 shadow card-hover"
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        height: "500px",
                        position: "relative",
                        borderRadius: "18px",
                        overflow: "hidden",
                      }}
                      onMouseEnter={() => handleMouseEnter(plan.title)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div className="card-body text-center">
                        <h5 className="card-title" style={{ color: "#00b2ff" }}>
                          {plan.title}
                        </h5>
                        <h3
                          className="card-price my-3"
                          style={{ color: "#000000", fontSize: "40px" }}
                        >
                          ₹{plan.priceINR} + GST
                          <span className="fading-line"></span>
                        </h3>
                        <ul className="list-unstyled">
                          <li style={commonListItemStyle}>
                            <span style={starIconStyle}>★</span>Fraud Detection
                          </li>
                          <li style={commonListItemStyle}>
                            <span style={starIconStyle}>★</span>
                            <span style={{ fontSize: "inherit" }}>
                              {[100, 250, 500, 399][index]}
                            </span>
                            <span
                              style={{ fontSize: "inherit", marginLeft: "5px" }}
                            >
                              {
                                ["Tickets", "Tickets", "Tickets", "Tickets"][
                                  index
                                ]
                              }
                            </span>
                          </li>

                          <li style={commonListItemStyle}>
                            <span style={starIconStyle}>★</span>24X7 Support
                          </li>
                          <li style={commonListItemStyle}>
                            <span style={starIconStyle}>★</span>Dedicated SOC
                            Team
                          </li>
                          <li style={commonListItemStyle}>
                            <span style={starIconStyle}>★</span>Call Support
                          </li>
                          <li style={commonListItemStyle}>
                            <span style={starIconStyle}>★</span>
                            <span style={{ fontSize: "inherit" }}>
                              {[25, 75, 150, 399][index]}
                            </span>
                            <span
                              style={{ fontSize: "inherit", marginLeft: "5px" }}
                            >
                              {
                                ["User Access", "User Access", "User Access", "User Access"][
                                  index
                                ]
                              }
                            </span>
                          </li>

                        </ul>
                        {/* <Button
                variant="primary"
                onClick={handleShow}
                className="mt-4"
              >
                Select Plan
              </Button> */}
                      </div>
                      <div className="gradient-line gradient-line-top"></div>
                      <div className="gradient-line gradient-line-bottom"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Modals */}
            <LoginSignupModal
              showModal={showModal}
              handleClose={handleClose}
              handleLogin={handleLogin}
              handleSignup={handleSignup}
            />
            <LoginModal
              show={showLoginModal}
              handleClose={() => setShowLoginModal(false)}
            />
            <SignupModal
              show={showSignupModal}
              handleClose={() => setShowSignupModal(false)}
            />
          </section>

          {/*About Us Section*/}

          {/*About Us Section*/}
          <section
            id="contact-about"
            style={{
              ...sectionStyle,
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "1.5rem 1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              {/* Left Side: Contact Form */}
              <div
                className="contact-form"
                style={{
                  flex: "1",
                  marginRight: "1rem",
                  width: "100%",
                  maxWidth: "380px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <h2
                  style={{
                    marginBottom: "1.5rem",
                    fontSize: "1.8rem",
                    color: "#000000",
                  }}
                >
                  Get In Touch
                </h2>
                <form style={{ width: "100%" }}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      style={{
                        ...inputStyle,
                        marginLeft: "1rem",
                        fontSize: "1rem",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      style={{
                        ...inputStyle,
                        marginLeft: "1rem",
                        fontSize: "1rem",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Your Message"
                      style={{
                        ...inputStyle,
                        marginLeft: "1rem",
                        fontSize: "1rem",
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      ...buttonStyle,
                      marginLeft: "1rem",
                      fontSize: "1rem",
                    }}
                  >
                    Send Message
                  </button>
                </form>
              </div>

              {/* Right Side: Contact Info, About Us, and Page Links */}
              <div
                className="contact-info"
                style={{
                  flex: "1",
                  marginLeft: "1rem",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <div style={{ marginBottom: "2.5rem" }}>
                  <h2
                    style={{
                      marginBottom: "1rem",
                      fontSize: "1.8rem",
                      color: "#000000",
                    }}
                  >
                    Contact Us
                  </h2>
                  <p style={{ color: "black", fontSize: "1rem" }}>
                    Email:{" "}
                    <a
                      href="mailto:support@phishroko.com"
                      style={{ fontSize: "1rem" }}
                    >
                      sales@phishroko.com
                    </a>
                  </p>
                  {/* <p style={{ color: "black", fontSize: "1rem" }}>
            Call Us : +16268424680
          </p>  */}

                  {/* <p style={{ color: "black", fontSize: "1rem" }}>
            Address: Sector 3, Vasundhara, Ghaziabad, Uttar Pradesh, India
          </p> */}
                </div>

                <div style={{ marginBottom: "2.5rem" }}>
                  <h2
                    style={{
                      marginBottom: "1rem",
                      fontSize: "1.8rem",
                      color: "#000000",
                    }}
                  >
                    About Us
                  </h2>
                  <p
                    style={{
                      fontSize: "1rem",
                      lineHeight: "1.4",
                      textAlign: "center",
                      maxWidth: "380px",
                      margin: "0 auto",
                      color: "#000000",
                    }}
                  >
                    At PhishRoko, we are committed to providing our customers
                    protection against cyber fraud for a safe & secure digital
                    life.
                  </p>
                </div>

                {/* Links to other pages */}
                <div style={{ marginBottom: "2.5rem" }}>
                  <h2
                    style={{
                      marginBottom: "1rem",
                      fontSize: "1.8rem",
                      color: "#000000",
                    }}
                  >
                    Quick Links
                  </h2>
                  <ul style={{ listStyleType: "none", padding: "0" }}>
                    <li>
                      <a
                        href="/privacypolicy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1rem" }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/terms-of-use"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1rem" }}
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        href="/return-refund-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1rem" }}
                      >
                        Return & Refund Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/ShippingAndDelivery"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1rem" }}
                      >
                        Shipping & Delivery Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Media Query for Specific 430x923 Screen Size */}
            <style jsx>{`
              @media (max-width: 430px) {
                #contact-about > div {
                  flex-direction: column;
                  align-items: center;
                }

                .contact-form {
                  order: 1;
                }

                .contact-info {
                  order: 2;
                  margin-top: 1.5rem;
                }

                #contact-about > div > div {
                  margin-right: 0;
                  margin-left: 0;
                  width: 100%;
                  max-width: 100%;
                }

                form input,
                form textarea {
                  font-size: 1rem;
                }

                form button {
                  font-size: 1rem;
                }
              }

              @media (max-height: 923px) {
                form input,
                form textarea {
                  font-size: 1rem;
                }

                form button {
                  font-size: 1rem;
                }
              }
            `}</style>
          </section>

          {/*Testimonials Section*/}
          {/* <section
            id="testimonials"
            style={{ padding: "50px 0", textAlign: "center" }}
          >
            <div style={{ width: "100%" }}>
              <h2 style={{ marginBottom: "40px" }}>What Our Users Say</h2>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <AiOutlineLeft
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    fontSize: "24px",
                    color: "#333",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={goToPrevious}
                />
                <animated.div
                  style={{
                    display: "flex",
                    width: `${totalPages * 100}%`,
                    ...slideProps,
                  }}
                >
                  {Array.from({ length: totalPages }).map((_, pageIndex) => (
                    <div
                      key={pageIndex}
                      style={{
                        display: "flex",
                        width: `${100 / totalPages}%`,
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 10px",
                      }}
                    >
                      {testimonials
                        .slice(
                          pageIndex * testimonialsPerPage,
                          (pageIndex + 1) * testimonialsPerPage
                        )
                        .map((testimonial, index) => (
                          <div
                            key={index}
                            style={{
                              maxWidth: "300px",
                              width: "100%",
                              margin: "0 10px",
                              border: "1px solid #ddd",
                              borderRadius: "10px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              backgroundColor: "#fff",
                              padding: "20px",
                              textAlign: "left",
                              transition: "transform 0.3s, box-shadow 0.3s",
                              cursor: "pointer",
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.boxShadow =
                                "0 8px 16px rgba(0, 0, 0, 0.2)")
                            }
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.boxShadow =
                                "0 4px 8px rgba(0, 0, 0, 0.1)")
                            }
                          >
                            <p>"{testimonial.text}"</p>
                            <h5>- {testimonial.name}</h5>
                          </div>
                        ))}
                    </div>
                  ))}
                </animated.div>
                <AiOutlineRight
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    fontSize: "24px",
                    color: "#333",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={goToNext}
                />
              </div>
            </div>

            {/* Mobile responsive styles */}
            <style>
              {`
      @media (max-width: 768px) {
        #testimonials .testimonial-slide {
          width: 100% !important;
        }
        #testimonials .testimonial-track {
          display: flex;
          flex-direction: column;
        }
      }
    `}
            </style>
          {/* </section> */}
        </main>
      </div>
    </>
  );
};

export default Homepage;
