import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import CreateTicketModal from "./CreateTicketModal";
import ViewTicketModal from "./ViewTicketModal";
import ImageModal from "./ImageModal";
import AttachmentsModal from "./AttachmentsModal";
import backgroundImage from "../../../../assets/images/subtle-prism1.png";

const ComplaintsPage = () => {
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const [showTicketDetailsModal, setShowTicketDetailsModal] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(20); // Fetch 20 tickets at a time
  const [hasMoreTickets, setHasMoreTickets] = useState(true);
  const [isFetchingTickets, setIsFetchingTickets] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  useEffect(() => {
    fetchTickets(); // Initial load
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreTickets && !isFetchingMore) {
          setIsFetchingMore(true);
          fetchTickets(true); // Fetch next page
        }
      },
      { threshold: 1.0 }
    );

    const target = document.querySelector("#load-more-trigger");
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [hasMoreTickets, isFetchingMore]);

  const fetchTickets = async (isNextPage = false) => {
    try {
      const token = localStorage.getItem("token");
      const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
  
      setIsFetchingTickets(true);
  
      const nextPageNumber = isNextPage ? pageNumber + 1 : 1;
  
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Ticket/user-tickets?userId=${userId}&pageNumber=${nextPageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const newTickets = response.data;
  
      if (newTickets.length === 0) {
        setHasMoreTickets(false); // No more tickets to load
      } else {
        setTickets((prevTickets) =>
          isNextPage
            ? [...prevTickets, ...newTickets.filter((ticket) => !prevTickets.some((t) => t.ticketId === ticket.ticketId))]
            : newTickets
        );
  
        if (isNextPage) {
          setPageNumber(nextPageNumber); // Increment pageNumber only after successful fetch
        }
      }
    } catch (error) {
      alert("Failed to fetch tickets. Please try again.");
      console.error("Failed to fetch tickets:", error);
    } finally {
      setIsFetchingTickets(false);
      setIsFetchingMore(false);
    }
  };
  

  const handleCardClick = (ticket) => {
    setSelectedTicket(ticket);
    setShowTicketDetailsModal(true);
  };

  const filteredTickets = tickets.filter((ticket) =>
    ticket.id.toString().includes(searchTerm)
  );

  return (
    <div
      style={{
        padding: "3rem 1rem",
        position: "relative",
        height: "100%",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "60px",
          left: "20%",
          right: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "calc(100% - 20%)",
          padding: "0 1rem",
          zIndex: 10,
          backgroundColor: "#fafafa",
          height: "60px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Button
          variant="primary"
          onClick={() => setShowCreateTicketModal(true)}
          style={{ marginRight: "10px" }}
        >
          Create Ticket
        </Button>
        <InputGroup className="d-flex" style={{ width: "100%", maxWidth: "500px" }}>
          <FormControl
            placeholder="Search by Ticket ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </div>

      <div className="mt-5" style={{ marginTop: "6rem" }}>
        <h2 style={{ textAlign: "center", marginTop: "2rem", fontSize: "2rem" }}>
          Your Tickets
        </h2>
        {isFetchingTickets && pageNumber === 1 ? (
          <div style={{ textAlign: "center", marginTop: "3rem" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Row className="justify-content-center">
            {filteredTickets.length > 0 ? (
              filteredTickets.map((ticket) => (
                <Col
                  key={ticket.ticketId}
                  xs={12}
                  sm={6}
                  md={4}
                  className="d-flex justify-content-center mb-4"
                >
                  <Card
                    className="p-3 border-0 shadow-lg"
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      borderRadius: "18px",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(ticket)}
                  >
                    <Card.Body>
                      <Card.Title
                        className="text-primary"
                        style={{ fontSize: "1.5rem" }}
                      >
                        ID: {ticket.ticketId}
                      </Card.Title>
                      <Card.Subtitle
                        className="mb-3"
                        style={{
                          fontSize: "1.25rem",
                          color: "#000",
                        }}
                      >
                        <strong>Title:</strong> {ticket.title}
                      </Card.Subtitle>
                      <Card.Text>
                        <strong>Description:</strong> {ticket.desc}
                      </Card.Text>
                      <p>
                        <strong>Status:</strong>{" "}
                        <span
                          style={{
                            color: ticket.status === 0 ? "blue" : "green",
                            fontWeight: "bold",
                          }}
                        >
                          {ticket.status === 0 ? "Open" : "Resolved"}
                        </span>
                      </p>
                      <p>
                        <strong>Created At:</strong>{" "}
                        {new Date(ticket.createdAt).toLocaleString()}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "#666",
                  paddingTop: "3rem",
                }}
              >
                No tickets available
              </p>
            )}
            {isFetchingMore && (
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            <div id="load-more-trigger" style={{ height: "1px" }}></div>
          </Row>
        )}
      </div>

      <CreateTicketModal
        show={showCreateTicketModal}
        onHide={() => setShowCreateTicketModal(false)}
      />

      <ViewTicketModal
        show={showTicketDetailsModal}
        onHide={() => setShowTicketDetailsModal(false)}
        selectedTicket={selectedTicket}
      />
    </div>
  );
};

export default ComplaintsPage;
